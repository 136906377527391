import axios from "axios";

// const url = "http://195.158.9.124:4101/api";
const url = "https://qabulapi.umft.university/api";

const helpers = {
  actions: {
    getAxios(context, payload) {
      return axios.get(`${context.getters.url}/${payload}`);
    },
    otherGetAxios(context, payload) {
      return axios.get(`${context.getters.uri}/${payload}`);
    },
    postAxios(context, payload) {
      return axios.post(`${context.getters.url}/${payload.url}`, payload.data);
    },
    async getAdmin(context, { link, params }) {
      const { data } = await axios.get(`${url}/${link}`, { params });
      console.log(data);
      return data;
    },
    async createApplication(context, phone) {
      const { data } = await axios.post(
        `${url}/application/create-application/`,
        { phone }
      );
      return data;
    },
    async checkApplication(context, params) {
      const { data } = await axios.get(
        `${url}/application/check-application/`,
        { params }
      );
      return data;
    },
    async sendApplication(context, payload) {
      const { data } = await axios.post(`${url}/student/application`, payload);
      return data;
    },
    async checkVaucherId({}, params) {
      try {
        const { data } = await axios.get(`${url}/voucher/check`, { params });
        return data;
      } catch (error) {
        return false;
      }
    },
  },
};

export default helpers;
