import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueSplide from "@splidejs/vue-splide";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// import Maska from "maska";

import VueCookies from "vue-cookies";
import i18n from "./lang/message";


const app = createApp(App)
  .use(ElementPlus)
  .use(i18n)
  .use(ElementPlusIconsVue)
  .use(VueCookies, {
    expires: "1d",
    path: "/",
    domain: "",
    secure: "",
    sameSite: "Lax",
  })  
  .use(VueSplide)
  .use(store)
  .use(router);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount("#app");
