import { convertDate } from "@/components/helpers/functions";
import axios from "axios";
export const students = {
  state: () => ({
    students: [],
    oneStudent: {
      studyId: "",
    },
    study: [
      "Umumiy o`rta maktab",
      "Kasb-hunar kolleji",
      "Akademik litsey",
      "Universitet/Institut",
    ],
    studyName: [
      "Umumiy o`rta maktab",
      "Kasb hunar kolleji",
      "Akademik litset",
      "Oliy ta`lim muassasi",
    ],
    types: [
      { title: "Kunduzgi", value: 0, ru: "Очное", en: "Full-time" },
      { title: "Sirtqi", value: 1, ru: "Заочное", en: "Part-time" },
    ],
    foreign: ["Ingliz tili", "Nemis tili", "Fransuz tili"],
    country: [
      { name: "O'zbekiston Respublikasi", code: "uz" },
      { name: "Qozog'iston Respublikasi", code: "kz" },
      { name: "Tojikiston Respublikasi", code: "tj" },
      { name: "Turkmaniston Respublikasi", code: "tk" },
      { name: "Qirg'iziston Respublikasi", code: "kg" },
      { name: "Boshqa", code: "ot" },
    ],
    activDirection: [],
    report: {},
    exams: [],
  }),

  getters: {
    report(state) {
      return state.report;
    },
    getActive(state) {
      return state.activDirection;
    },
    studyName(state) {
      return state.studyName;
    },
    country(state) {
      return state.country;
    },
    foreign(state) {
      return state.foreign;
    },
    typeCount(state) {
      let count = [];
      state.students.forEach((student) => {
        count[student.studyType] += 1;
      });
      return count;
    },
    types(state) {
      return state.types;
    },
    study(state) {
      return state.study;
    },
    getStudents(state) {
      return state.students;
    },
    oneStudent(state) {
      return state.oneStudent;
    },
    examsList({ exams }) {
      return exams;
    },
  },
  mutations: {
    report(state, payload) {
      state.report = payload;
    },
    actDirection(state, payload) {
      state.activDirection = payload;
    },
    addStudent(state, payload) {
      state.students.unshift(payload);
    },

    allStudents(state, payload) {
      state.students = payload;
    },

    oneStudent(state, payload) {
      state.oneStudent = payload;
    },

    students(state, payload) {
      state.students = payload;
    },
    deleteStudent(state, payload) {
      let index = state.students.findIndex((f) => f._id == payload);
      if (index !== -1) {
        state.students.splice(index, 1);
      }
    },
    upStudent(state, payload) {
      let index = state.students.findIndex((f) => f._id == payload._id);
      if (index !== -1) {
        state.students[index] = payload;
      }
    },
    exams(state, payload) {
      state.exams = payload.map((exam) => {
        exam.date = convertDate(exam.date);
        return exam;
      });
    },
  },

  actions: {
    oneStudent(context, payload) {
      axios
        .get(`${context.getters.uri}/student/getone/${payload}`)
        .then((res) => {
          // console.log(res.data)
          context.commit("oneStudent", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReport(context, payload) {
      if (payload) {
        axios
          .get(`${context.getters.uri}/student/getreport/${payload}`)
          .then((res) => {
            if (res.status == 200) {
              context.commit("report", res.data);
            }
          });
      }
    },
    activeDirection(context) {
      axios.get(`${context.getters.uri}/direction`).then((res) => {
        // console.log(res.data)
        context.commit("actDirection", res.data);
      });
    },
    async exams({ commit, getters }) {
      let res = await axios.get(`${getters.uri}/exam`, {
        params: {
          last: 2,
        },
      });
      if (res.status == 200) {
        // console.log(res.data)
        commit("exams", res.data.exams);
      }
    },
    removeFile(context, payload) {
      return axios.post(`${context.getters.uri}/student/files/delete`, {
        resFile: payload,
      });
    },
    sendFeedback(context, payload) {
      return axios.post(`${context.getters.url}/contact`, payload);
    },
    sendSms(context, payload) {
      return axios.post(`${context.getters.uri}/student/sendsms`, payload, {
        headers: context.getters.header,
      });
    },
    addStudent(context, payload) {
      return axios.post(`${context.getters.uri}/student/reg`, payload);
    },
    
  },
};
